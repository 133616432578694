<template>
  <div class="reserveBook">
    <van-form @submit="onSubmit" class="forms">
        <div class="iconForm">
            <van-field
                readonly
                clickable
                name="picker"
                :value="model.session"
                label="参展场次"
                placeholder="请选择参展场次"
                @click="start.session = true"
                :rules="[{ required: true}]"
                :show-error="false"
            />
            <van-icon name="play" class="playIcons" />
            <van-popup v-model="start.session" position="bottom">
                <van-picker
                    show-toolbar
                    :columns="session_List"
                    @confirm="onConfirmsSession"
                    @cancel="start.session = true"
                />
            </van-popup>
        </div>
        <van-field
            v-model="model.AdminNane"
            name="AdminNane"
            label="公司名称"
            placeholder="请输入公司名称/组织名称"
            :rules="[{ required: true}]"
            :show-error="false"
        />
        <van-field
            v-model="model.name"
            name="name"
            label="姓名"
            placeholder="请输入姓名"
            :rules="[{ required: true}]"
            :show-error="false"
        />
        <van-field
            v-model="model.post"
            name="post"
            label="职位"
            placeholder="请输入职位"
        />
        <div class="iconForm">
            <van-field
                readonly
                clickable
                name="picker"
                :value="model.gender"
                label="性别"
                placeholder="请选择性别"
                @click="start.gender = true"
            />
            <van-icon name="play" class="playIcons" />
            <van-popup v-model="start.gender" position="bottom">
                <van-picker
                    show-toolbar
                    :columns="gender_List"
                    @confirm="onConfirmGender"
                    @cancel="start.gender = true"
                />
            </van-popup>
        </div>

        <van-field
            v-model="model.phone"
            name="phone"
            label="手机号码"
            placeholder="请输入手机号"
            :rules="[{ pattern:/^1\d{10}$/}]"
            :show-error="false"
        />
        <div class="iconForm">
            <van-field
                readonly
                clickable
                name="picker"
                :value="model.nature"
                label="公司性质"
                placeholder="请选择公司性质"
                @click="start.nature = true"
            />
            <van-icon name="play" class="playIcons" />
            <van-popup v-model="start.nature" position="bottom">
                <van-picker
                    show-toolbar
                    :columns="nature_list"
                    @confirm="onConfirmNature"
                    @cancel="start.nature = false"
                />
            </van-popup>
        </div>
        <van-field
            v-model="model.limit"
            label-width="6.8em"
            name="年度营业额(万)"
            label="年度营业额(万)"
            placeholder="请输入年度营业额"
        />
        <van-field
            v-model="model.email"
            name="email"
            label="邮箱"
            placeholder="请输入邮箱"
            :rules="[{ pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/}]"
            :show-error="false"
        />
        <div class="iconForm">
            <van-field
                readonly
                clickable
                name="area"
                :value="model.region"
                label="地区"
                placeholder="请选择地区"
                @click="start.region = true"
                :rules="[{ required: true}]"
                :show-error="false"
            />
            <van-icon name="play" class="playIcons" />
            <van-popup v-model="start.region" position="bottom">
                <van-area
                    :area-list="areaList"
                    :columns-num="2"
                    @confirm="onConfirmRegion"
                    @cancel="start.region = false"
                />
            </van-popup>
        </div>
        <van-field
            v-model="model.site"
            name="详细地址"
            label="详细地址"
            placeholder="请输入详细地址"
            :rules="[{ required: true}]"
            :show-error="false"
        />
        <div class="iconForm">
            <van-field
                readonly
                clickable
                name="brand"
                :value="model.brand"
                label="主营分类"
                placeholder="请选择主营分类"
                @click="start.brand = true"
                :rules="[{ required: true}]"
                :show-error="false"
            />
            <van-icon name="play" class="playIcons" />
            <van-popup v-model="start.brand" position="bottom">
                <van-picker
                    show-toolbar
                    :columns="nature_brand"
                    @confirm="onConfirmbrand"
                    @cancel="start.brand = false"
                />
            </van-popup>
        </div>
        <div class="subButton">
            <van-button round block type="info" native-type="submit" class="btnSubmit" color="#46A05C" @click="submitGetQuery">提交</van-button>
        </div>
    </van-form>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
import {
  boothCreateAdd,
  boothList
} from "@/api/index";
export default {
  data(){
    return {
        areaList,
        model:{
            session:'',
            AdminNane:'',
            name:'',
            post:'',
            gender:'',
            phone:'',
            nature:'',
            limit:'',
            email:'',
            region:'',
            site:'',
            brand:'',
        },
        start:{
            gender:false,
            nature:false,
            region:false,
            session:false,
            brand:false,
        },
        gender_List:['男性', '女性'],
        
        session_List:[],

        nature_brand:[],


        nature_list:['厂家', '经销商', '个体户'],
        province:'',
        city:'',
    }
  },
  created(){
    this.initboothList();
  },
  methods:{
    onConfirmGender(val){
        this.model.gender = val;
        this.start.gender = false;
    },
    onConfirmNature(val){
        this.model.nature = val;
        this.start.nature = false;
    },
    onConfirmbrand(val){
        this.model.brand = val;
        this.start.brand = false;
    },
    initboothList(){
        boothList().then(res=>{
            res.data.event_cate.map(item=>{
                this.session_List.push(item.event_name)
            })
            res.data.exhibitor_cate.map(item=>{
                this.nature_brand.push(item.title)
            })
        })
    },
    onConfirmRegion(val){
        const datas =  `${val[0].name} / ${val[1].name}`;
        this.model.region = datas;
        this.province = val[0].name
        this.city = val[1].name
        this.start.region = false;
    },
    onConfirmsSession(val){
        this.model.session = val;
        this.start.session = false;
    },
    onSubmit(values) {
      console.log('submit', values);
    },
    submitGetQuery(){
        let datas = {
            session:this.model.session,
            organization:this.model.AdminNane,
            name:this.model.name,
            position:this.model.post,
            sex:this.model.gender,
            phone:this.model.phone,
            nature:this.model.nature,
            turnover:this.model.limit,
            emali:this.model.email,
            province:this.province,
            city:this.city,
            address:this.model.site,
            brand:this.model.brand,
        }
        boothCreateAdd(datas).then(res=>{
            console.log(res,323232323)
        })
    }
  }
}
</script>

<style scoped lang="less">
.subButton{
    position: fixed;
    margin: 43px auto auto;
    width: 100%;
    bottom: 22px;
    background: #fff;
}
.btnSubmit{
    border-radius: 8px;
    height: 40px;
    margin:0 auto;
    width: 45%;
}
.reserveBook{
    background:#fff;
}
.iconForm{
    position: relative;
    ::v-deep{
        .van-field__control{
            padding-right:20px;
        }
        
    }
    .playIcons{
        position: absolute;
        top: 19px;
        right: 14px;
        transform: rotate(90deg);
        color: #999;
    }
}
.forms{
    padding-top: 10px;
    padding-bottom: 16%;
    ::v-deep{
        .van-field__control{
            text-align: right;
        }
        .van-field__error-message{
            text-align: right;
        }
    }
}
::v-deep{
    .van-cell{
        padding-top: 16px;
    }
}
</style>
